import Api from './Api' // axois object created in api.js

export default {
  // export its register method
  orgWithdrawRequest ({ organizationId, amountFenReq }) {
    console.log(
      `Services/FianceService.orgWithdrawRequest starts... organizationId: ${organizationId}, amount[fen]: ${amountFenReq}`
    )
    return Api().post('finance/org-withdraw-request', {
      organization_id: organizationId,
      amount_fen_req: amountFenReq
    })
  },
  getOrgWithdrawRecords ({
    organizationId,
    withdrawStatusCodes,
    limit,
    offset
  }) {
    console.log(
      `Services/FianceService.getOrgWithdrawRecords starts... organization_id: ${organizationId}, withdrawStatusCodes: ${withdrawStatusCodes}, limit: ${limit}, offset: ${offset}`
    )
    return Api().get('finance/org-withdraw-records', {
      params: {
        organizationId: organizationId,
        withdrawStatusCodes,
        limit,
        offset
      }
    })
  }
}
