<template>
<div>
  <h1 class="title grey--text my-3">我的收入</h1>
  <div>
    <v-card flat class="mb-5">
      <v-layout align-center justify-center row wrap class="pa-4">
        <v-flex xs12>
          <div class="caption grey--text">绑定支付宝: {{ organizationInfo.alipay_account? alipayAccountMasked: '尚未绑定' }}
            <v-tooltip top v-if="organizationInfo.alipay_account">
              <template v-slot:activator="{ on }">
                  <v-icon small v-on="on"> remove_red_eye </v-icon>
              </template>
              <span> {{organizationInfo.alipay_account}} </span>
            </v-tooltip>
          </div>
        </v-flex>
        <v-flex xs6>
          <div class="caption grey--text">账户余额(元)</div>
          <div class="headline">{{ organizationTransactionRecords.length > 0 ? organizationTransactionRecords[0].balance_fen / 100 : '-'}}</div>
        </v-flex>
        <v-flex xs6>
          <div class="caption grey--text">动态信用押金(元)
            <v-tooltip top v-if="organizationInfo.freezing_amount_fen">
              <template v-slot:activator="{ on }">
                  <v-icon small v-on="on"> help_outline </v-icon>
              </template>
              <span> 动态更新：本机构所有在读学员单次活动课费总和的2倍, 且不小于1000元。可前往 “项目及学员>学员” 查看本机构活跃学员列表。</span>
            </v-tooltip>
          </div>
          <div class="headline">{{ organizationInfo.freezing_amount_fen / 100 }}</div>
        </v-flex>
        <v-flex xs12>
          <!-- <v-alert class="ma-5" type="success" dismissible>
            <strong>机构收入计算</strong>
            <p>机构课费收入 = 学员活动付费 - 平台服务费 - 承接家庭场地费</p>
            <p>其中：平台服务费 = 每名学员每次活动付费 X 结课时平台服务费率 </p>
            <p>其中：承接家庭场地费，见课堂场地承接协议中的详细规定 </p>
          </v-alert> -->
        </v-flex>
      </v-layout>
    </v-card>
  </div>
  <v-layout justify-center row wrap>
    <div class="d-flex flex-column">
      <v-btn class="ma-3" color='primary' :disabled="hasExistingRequest" @click="withdrawRequestDialog = true"> {{hasExistingRequest?'申请处理中...':'申请提现'}}  </v-btn>
    </div>
    <v-dialog v-model="withdrawRequestDialog" max-width="400px" right persistent>
      <v-card>
        <v-card-title>
          <span class="headline"> 申请提现 </span>
        </v-card-title>

        <v-card-text>
          <v-form name="org-withdraw-request-form" ref="orgWithdrawRequestForm" autocomplete='off' v-model="isOrgWithdrawRequestFormVisiblyValid" lazy-validation>
            <v-container>
              <v-text-field
                label="金额(元)"
                outlined
                v-model="withdrawAmountYuan"
                :rules="withdrawAmountYuanRule"
              ></v-text-field>
            </v-container>
          </v-form>
          <v-alert :value="withdrawRequestErrMsg.length > 0" type="error" outlined>
            {{ withdrawRequestErrMsg }}
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="withdrawDialogClose">关闭</v-btn>
          <v-btn color="primary" @click="withdrawRequest" :disabled="!isOrgWithdrawRequestFormVisiblyValid || !withdrawAmountYuan">申请</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
  <v-card>
  <v-tabs
    v-model="tab"
    centered
    icons-and-text
  >
    <v-tabs-slider></v-tabs-slider>

    <v-tab href="#organization-income-records">
      收入
      <v-icon>money</v-icon>
    </v-tab>

    <v-tab href="#withdraw-records">
      提现
      <v-icon>payment</v-icon>
    </v-tab>
  </v-tabs>

  <v-tabs-items v-model="tab">
    <v-tab-item
      value="organization-income-records"
    >
      <v-container class="my-5">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <!-- <th class="text-left">类别</th> -->
                <th class="text-left">收入(元)</th>
                <th class="text-left">余额(元)</th>
                <th class="text-left">学员</th>
                <th class="text-left">活动</th>
                <th class="text-left">授课时间</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in organizationTransactionRecords" :key="item.org_money_record_id">
                <!-- <td class="caption">{{ item.category }}</td> -->
                <td class="caption">{{ item.amount_fen / 100 }}</td>
                <td class="caption">{{ item.balance_fen / 100 }}</td>
                <td class="caption">{{ item.student }}</td>
                <td class="caption">{{ item.lesson }}</td>
                <td class="caption">{{ item.lesson_time }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-container>
      <v-layout justify-center class="pa-5">
        <v-btn
          text
          outlined
          color="grey"
          :loading="loading"
          :disabled="loading"
          @click="pushOrganizationTransactionRecords(organizationId, 20, organizationTransactionRecords.length)">
          加载更多
          <template v-slot:loader>
            <span class="custom-loader">
              <v-icon light>cached</v-icon>
            </span>
          </template>
        </v-btn>
      </v-layout>
    </v-tab-item>
    <v-tab-item
      value="withdraw-records"
      >
      <v-container class="my-5">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">申请时间</th>
                <th class="text-left">申请金额(元)</th>
                <th class="text-left">状态</th>
                <th class="text-left">通过时间</th>
                <th class="text-left">提现金额(元)</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in organizationWithdrawRecords" :key="item.org_withdraw_id">
                <td class="caption">{{ item.request_time }}</td>
                <td class="caption">{{ item.amount_yuan_req }}</td>
                <td class="caption">{{ item.withdraw_status }}</td>
                <td class="caption">{{ item.withdraw_time }}</td>
                <td class="caption">{{ item.amount_yuan_withdraw }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-container>
     </v-tab-item>
    </v-tabs-items>
  </v-card>
  <!-- display overlay on the whole page while loading -->
  <v-overlay :value="$store.state.queryingState">
    <v-progress-circular
      indeterminate
      color=""
    ></v-progress-circular>
  </v-overlay>
</div>
</template>

<script>
import ProgramService from '@/services/ProgramService'
import FinanceService from '@/services/FinanceService'
import OrganizationService from '@/services/OrganizationService'
import moment from 'moment'
import util from '@/util'

export default {
  components: {
  },
  computed: {
    hasExistingRequest () {
      const ongoingRequests = this.organizationWithdrawRecords.filter((record) => {
        if (record.withdraw_status_code !== 3) return true
      })
      if (ongoingRequests.length > 0) {
        return true
      } else {
        return false
      }
    },
    alipayAccountMasked: function () {
      if (this.organizationInfo.alipay_account) {
        return util.maskString({ str: this.organizationInfo.alipay_account })
      } else {
        return null
      }
    }
  },
  data () {
    return {
      tutorId: null,
      organizationId: null,
      organizationTransactionRecords: [],
      organizationWithdrawRecords: [],
      tab: null,
      withdrawRequestDialog: false,
      withdrawAmountYuan: null,
      withdrawAmountYuanRule: [
        value => !!value || '请填写金额',
        value => {
          const pattern = /^(\d{1,3},?(\d{3},?)*\d{3}(\.\d{0,2})?|\d{1,3}(\.\d{0,2})?|\.\d{1,2}?)$/
          return pattern.test(value) || '请输入正确的金额'
        },
        value => {
          return parseInt(value) > 0 || '请输入大于零的金额'
        }
      ],
      isOrgWithdrawRequestFormVisiblyValid: true,
      err: null,
      withdrawRequestErrMsg: '',
      loading: false,
      organizationInfo: {}
    }
  },
  async mounted () {
    // set queryingState
    this.$store.dispatch('setQueryingState', true)
    this.tutorId = this.$store.state.account.tutor.id

    if (this.$store.state.account.organizations.length > 0) {
      this.organizationId = this.$store.state.account.organizations[0].id
      // get organization info
      const res = await OrganizationService.getOrganizationInfo({ organizationId: this.organizationId })
      this.organizationInfo = res.data

      // get organizationTransactionRecords
      this.pushOrganizationTransactionRecords(this.organizationId, 50, this.organizationTransactionRecords.length)

      // get organizationWithdrawRecords
      this.organizationWithdrawRecords = await this.getorganizationWithdrawRecords(this.organizationId)

      this.organizationWithdrawRecords.forEach(organizationWithdrawRecord => {
        organizationWithdrawRecord.withdraw_time = moment(organizationWithdrawRecord.withdraw_time).format('YYYY-MM-DD HH:mm')
        organizationWithdrawRecord.request_time = moment(organizationWithdrawRecord.request_time).format('YYYY-MM-DD HH:mm')
        organizationWithdrawRecord.amount_yuan_req = -1 * organizationWithdrawRecord.amount_fen_req / 100
        organizationWithdrawRecord.amount_yuan_withdraw = -1 * organizationWithdrawRecord.amount_fen_withdraw / 100
      })
    } else {
      console.log('该角色不具有机构账号！')
    }

    // set queryingState
    this.$store.dispatch('setQueryingState', false)
  },
  methods: {
    async pushOrganizationTransactionRecords (organizationId, limit, offset) {
      this.loading = true
      // get organizationTransactionRecords
      let newRecords = await this.getOrganizationTransactionRecordsByOrganizationId(organizationId, limit, offset)

      newRecords.forEach(organizationTransactionRecord => {
        organizationTransactionRecord.lesson_time = moment(organizationTransactionRecord.lesson_time).format('YYYY-MM-DD')
        organizationTransactionRecord.amount_yuan = organizationTransactionRecord.amount_fen / 100
        organizationTransactionRecord.balance_yuan = organizationTransactionRecord.balance_fen / 100
        organizationTransactionRecord.payment_yuan = organizationTransactionRecord.payment_fen / 100
      })

      // Array.prototype.push.apply(this.organizationTransactionRecords, newRecords)
      this.organizationTransactionRecords.push(...newRecords)
      this.loading = false
    },
    async getOrganizationTransactionRecordsByOrganizationId (organizationId, limit, offset) {
      console.log('\n#getOrganizationTransactionRecordsByOrganizationId starts ... ')
      console.log('organizationId: ', organizationId)
      const res = await ProgramService.getOrganizationTransactionRecordsByOrganizationId(organizationId, limit, offset)
      console.log('retrieve OrganizationTransactionRecords successful! \nOrganizationTransactionRecords: ', res.data)
      return res.data
    },
    async getorganizationWithdrawRecords (organizationId) {
      console.log('\n#getorganizationWithdrawRecords starts ... ')
      console.log('organizationId: ', organizationId)
      const res = await FinanceService.getOrgWithdrawRecords({ organizationId })
      console.log('retrieve organizationWithdrawRecords successful! \nOrganizationWithdrawRecords: ', res.data)
      return res.data
    },
    async withdrawRequest () {
      console.log(`withdraw request submitted ...`)
      const organizationId = this.organizationId
      const amountFenReq = parseInt(parseFloat(this.withdrawAmountYuan) * 100 * -1)
      try {
        const res = await FinanceService.orgWithdrawRequest({ organizationId, amountFenReq })
        console.log(res.data.msg)
        this.$router.go()
      } catch (err) {
        this.err = err
        console.log(`err: ${err.response.data.msg}`)
        this.withdrawRequestErrMsg = err.response.data.msg[0]
      }
    },
    withdrawDialogClose () {
      this.withdrawRequestDialog = false
      this.withdrawAmountYuan = null
      this.withdrawRequestErrMsg = ''
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
